<template>
  <div class="homepage-container">
    <!-- 背景图片 -->
    <img src="@/assets/back.png" alt="Background" class="background-image" />

    <!-- 邮箱输入框和按钮 -->
    <div class="email-section">
      <div class="email-background-wrapper">
        <img src="@/assets/email-background.svg" alt="Email Background" class="email-background" />
        <div class="email-content">
          <input
            class="email-input"
            type="email"
            placeholder="Enter your email address to join waitlist."
            v-model="email"
            :disabled="loading"
          />
          <button
            class="claim-button"
            @click="submitEmail"
            :disabled="loading"
          >
            {{ loading ? 'Sending...' : 'Claim now' }}
          </button>
        </div>
      </div>

      <!-- 消息提示 -->
      <div v-if="showMessage" class="message-overlay" @click="showMessage = false">
        <div class="message-box" :class="{ 'success': !error, 'error': error }">
          <span class="message-text">{{ messageText }}</span>
          <button class="close-button" @click.stop="showMessage = false">&times;</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      email: "",
      loading: false,
      error: null,
      showMessage: false,
      messageText: ""
    };
  },
  methods: {
    async submitEmail() {
      if (!this.email) return;

      try {
        this.loading = true;
        this.error = null;

        const response = await fetch('https://gamlifyweb-gebjgnchc0a8hba4.eastus2-01.azurewebsites.net/api/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: this.email })
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Submission failed');
        }

        this.messageText = 'Confirmation email has been sent, please check your inbox.';
        this.error = null;
        this.showMessage = true;
        this.email = ''; // 清空输入框

        // 3秒后自动关闭消息
        setTimeout(() => {
          this.showMessage = false;
        }, 3000);

      } catch (error) {
        console.error('Error:', error);
        this.error = true;
        this.messageText = error.message;
        this.showMessage = true;

        // 错误消息也是3秒后自动关闭
        setTimeout(() => {
          this.showMessage = false;
        }, 3000);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
/* 页面容器 */
.homepage-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  font-family: Arial, sans-serif;
}

/* 背景图片 */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 邮箱输入框和按钮区域 */
.email-section {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  display: flex;
  justify-content: center;
}

/* 邮箱背景容器 */
.email-background-wrapper {
  position: relative;
  width: 503px;
  height: 54px;
  display: flex;
  align-items: center;
}

/* 邮箱背景 */
.email-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 514px;
  height: 142px;
  z-index: 1;
  object-fit: cover;
  transform: translateY(-40px);
}

/* 邮箱内容容器 */
.email-content {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 54px;
  background: rgba(255, 255, 255, 0.2); /* 半透明背景 */
  border-radius: 8px;
  padding: 0 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* 阴影效果 */
  z-index: 2;
}

/* 输入框 */
.email-input {
  flex: 1;
  height: 40px;
  background: transparent;
  border: none;
  padding: 0 15px;
  color: #fff;
  outline: none;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 6px;
  caret-color: #fff;
}

/* 按钮样式 */
.claim-button {
  width: 134px;
  height: 43px;
  border: none;
  background: linear-gradient(90deg, #3A55FF 0%, #5629F7 100%);
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 15px rgba(86, 41, 247, 0.3);
  margin-right: -5px;
  transition: all 0.3s ease;
}

.claim-button:hover {
  background: linear-gradient(90deg, #4B63FF 0%, #6A3FFF 100%);
  transform: translateY(-1px);
}

/* 消息提示样式 */
.message-overlay {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  cursor: pointer;
}

.message-box {
  padding: 15px 40px 15px 20px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  position: relative;
  animation: slideDown 0.3s ease-out;
}

.message-box.success {
  border-left: 4px solid #4CAF50;
}

.message-box.error {
  border-left: 4px solid #f44336;
}

.message-text {
  color: #333;
  font-size: 14px;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 20px;
  color: #666;
  cursor: pointer;
  padding: 0 5px;
}

.close-button:hover {
  color: #333;
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* 禁用状态样式 */
.email-input:disabled,
.claim-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.claim-button:disabled {
  background: linear-gradient(90deg, #8891d6 0%, #9183d3 100%);
}

/* 响应式样式 */
@media screen and (max-width: 768px) {
  .email-section {
    width: 90%; /* 宽度调整为屏幕的90% */
  }

  .email-content {
    padding: 0 5px;
  }

  .email-input {
    font-size: 14px;
    height: 36px;
  }

  .claim-button {
    font-size: 14px;
    height: 36px;
  }
}
</style>
